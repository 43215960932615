import axiosInstance from '../http/axiosSetup';

export type smsAuthType = {
    smsId: number;
    smsCode: string;
};

export default class ContractService {
    static getProjectProperties = (queryParams) => {
        return axiosInstance
            .get('project/properties', {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static sendMail = (body) => {
        return axiosInstance
            .get(`/contracts/${body?.partnerId}/${body?.contractNumber}/registration-mail`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveBWGContract = (body) => {
        return axiosInstance
            .post('/contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveMobileContract = (body) => {
        return axiosInstance
            .post('/contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static verifySmsCode = (body: smsAuthType) => {
        return axiosInstance
            .post('/sms-auth', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static sendSmsCode = (body: Record<'phoneNumber', string>, partnerId: string, contractNumber: number) => {
        return axiosInstance
            .post(`contracts/${partnerId}/${contractNumber}/sms-sign`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static emailCheck = (body: Record<string, any>) => {
        return axiosInstance
            .post(`validations/email-address-validation`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
}
