import { saveNewContract, SAVE_NEW_CONTRACT, verifySmsCode, VERIFY_SMS_CODE, SEND_MAIL, sendMail, sendSms, SEND_SMS, GET_PROJECT_PROPERTIES, getProjectProperties } from './actions';
import { takeLatest } from 'redux-saga/effects';

export default [
    takeLatest(SEND_MAIL, sendMail),
    takeLatest(SAVE_NEW_CONTRACT, saveNewContract),
    takeLatest(VERIFY_SMS_CODE, verifySmsCode),
    takeLatest(SEND_SMS, sendSms),
    takeLatest(GET_PROJECT_PROPERTIES, getProjectProperties),
];
