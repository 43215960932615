import ContractService, { smsAuthType } from '@src/api/services/contractService';
import RootState from '@src/interfaces/RootState';
import { Dayjs } from 'dayjs';
import { put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING } from './../ui/actions';
import cookie from 'react-cookies';

/** Saga Actions */
export const SAVE_NEW_CONTRACT = 'SAVE_NEW_CONTRACT';
export const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
export const SEND_MAIL = 'SEND_MAIL';
export const SEND_SMS = 'SEND_SMS';
export const GET_PROJECT_PROPERTIES = 'GET_PROJECT_PROPERTIES';

/** Reducer Actions */
export const SET_PROJECT_PROPERTIES = 'SET_PROJECT_PROPERTIES';
export const SET_CONTRACT_SAVED = 'SET_CONTRACT_SAVED';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PARTNER = 'SET_PARTNER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_ACCOUNT_HOLDER = 'SET_ACCOUNT_HOLDER';
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export const SET_CONTRACT_NUMBER = 'SET_CONTRACT_NUMBER';
export const RESET_CONTRACT_STORE = 'RESET_CONTRACT_STORE';
export const SET_SMS_ID = 'SET_SMS_ID';

export function* getProjectProperties(actionPayload) {
    // partnerId
    // const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
    yield put({ type: SET_LOADING, val: true });

    try {
        const queryParams = {
            prtId: actionPayload?.val,
        };

        const response = yield ContractService.getProjectProperties(queryParams);
        if (response?.status === 200) {
            yield put({ type: SET_PROJECT_PROPERTIES, val: response?.data[0] });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* sendMail() {
    yield put({ type: SET_LOADING, val: true });
    const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
    const getContractNumber = yield select((state: RootState) => state?.contract?.contractNumber);
    try {
        const payload = {
            partnerId: getPartnerData?.partner,
            contractNumber: getContractNumber,
        };
        const response = yield ContractService.sendMail(payload);
        if (response?.status === 200) {
            return;
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* sendSms() {
    yield put({ type: SET_LOADING, val: true });
    const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
    const contractNumber = yield select((state: RootState) => state?.contract?.contractNumber);
    const phoneNumber = yield select((state: RootState) => state?.contract?.customerModel?.phoneNumber) ?? '"phone number"';
    const payload = {
        phoneNumber: phoneNumber,
    };
    try {
        const response = yield ContractService.sendSmsCode(payload, getPartnerData?.partner, contractNumber);
        if (response?.status === 200) {
            yield put({ type: SET_SMS_ID, val: response?.data?.smsId });
            // Sending sms successfully doesn't trigger notification
            return;
        } else {
            // Else does
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* verifySmsCode() {
    yield put({ type: SET_LOADING, val: true });
    const smsId = yield select((state: RootState) => state?.contract?.smsId);
    const paperlessCode = yield select((state: RootState) => (state?.form?.paperless as Record<'values', Record<'code', string>>)?.values?.code);

    const payload: smsAuthType = {
        smsId: smsId,
        smsCode: paperlessCode,
    };
    try {
        const response = yield ContractService.verifySmsCode(payload);
        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_SAVED, val: true });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Save new contract
 */
export function* saveNewContract() {
    yield put({ type: SET_LOADING, val: true });
    const schema = cookie.load('SCH_ID');
    const getModels = yield select((state: RootState) => state?.contract);
    let payload;
    if (schema === 'N_INDEP_BWG') {
        payload = new FormData();
        payload = {
            contract: {
                taxIdentificationNumber: getModels?.customerModel?.vatNumber,
                city: getModels?.customerModel?.city,
                street: getModels?.customerModel?.street,
                postcode: getModels?.customerModel?.postCode,
                email: getModels?.customerModel?.email,
                articleType: getModels?.productModel?.model,
                serialNumber: getModels?.productModel?.serial,
                warrantySalesDate: (getModels?.partnerModel?.sellingDate as Dayjs).format('YYYY-MM-DD'),
                shipDate: (getModels?.partnerModel?.deliveryDate as Dayjs).format('YYYY-MM-DD'),
                articlePrice: getModels?.productModel?.price,
                brandId: getModels?.productModel?.brand,
                groupId: getModels?.productModel?.group,
                languageId: getModels?.customerModel?.language,
                countryId: getModels?.customerModel?.country,
                streetNumber: getModels?.customerModel?.houseNumber,
                phoneNumber: getModels?.customerModel?.phoneNumber,
                shopId: getModels?.partnerModel?.shop,
                employeeId: getModels?.partnerModel?.employee,
                status: 'A',
                lastname: getModels?.customerModel?.customerName,
                warrantyTypeId: getModels?.productModel?.warranty,
                receiptNumber: getModels?.partnerModel?.receiptNumber,
                comment: getModels?.productModel?.comment,
                partnerId: getModels?.partnerModel?.partner,
                warrantySalesPrice: getModels?.productModel?.warrantySellingPrice,
            },
        };
    } else if (schema === 'N_INDEP_MOBILE') {
        payload = {
            contract: {
                taxIdentificationNumber: getModels?.customerModel?.vatNumber,
                articleType: getModels?.productModel?.model,
                articlePrice: getModels?.productModel?.price,
                brandId: getModels?.productModel?.brand,
                city: getModels?.customerModel?.city,
                comment: getModels?.productModel?.comment,
                countryId: getModels?.customerModel?.country,
                email: getModels?.customerModel?.email,
                employeeId: getModels?.partnerModel?.employee,
                groupId: getModels?.productModel?.group,
                languageId: getModels?.customerModel?.language,
                lastname: getModels?.customerModel?.customerName,
                partnerId: getModels?.partnerModel?.partner,
                phoneNumber: getModels?.customerModel?.phoneNumber,
                postcode: getModels?.customerModel?.postCode,
                receiptNumber: getModels?.partnerModel?.receiptNumber,
                salesDate: (getModels?.partnerModel?.sellingDate as Dayjs).format('YYYY-MM-DD'),
                shipDate: (getModels?.partnerModel?.deliveryDate as Dayjs).format('YYYY-MM-DD'),
                serialNumber: getModels?.productModel?.serial,
                shopId: getModels?.partnerModel?.shop,
                status: 'A',
                street: getModels?.customerModel?.street,
                streetNumber: getModels?.customerModel?.houseNumber,
                warrantySalesDate: (getModels?.partnerModel?.deliveryDate as Dayjs).format('YYYY-MM-DD'),
                warrantySalesPrice: getModels?.productModel?.warrantySellingPrice,
                warrantyTypeId: getModels?.productModel?.warranty,
            },
            account: {
                languageId: getModels?.accountHolderModel?.languageAh,
                countryId: getModels?.accountHolderModel?.countryAh,
                customerName: getModels?.accountHolderModel?.customerNameAh,
                city: getModels?.accountHolderModel?.cityAh,
                postcode: getModels?.accountHolderModel?.postCodeAh,
                street: getModels?.accountHolderModel?.streetAh,
                streetNumber: getModels?.accountHolderModel?.houseNumberAh,
                phoneNumber: getModels?.accountHolderModel?.phoneNumberAh,
                email: getModels?.accountHolderModel?.emailAh,
                ibanNumber: getModels?.bankAccountModel?.iban.toUpperCase(),
                bankCode: getModels?.bankAccountModel?.iban?.slice(4, 7),
                bankCountryId: getModels?.bankAccountModel?.bankCountry,
                bankCity: getModels?.bankAccountModel?.bankCity,
                bankPostcode: getModels?.bankAccountModel?.bankPostCode,
                bankStreet: getModels?.bankAccountModel?.bankStreet,
                bankStreetNumber: getModels?.bankAccountModel?.bankStreetNumber,
            },
        };
    }
    try {
        let response;
        if (schema === 'N_INDEP_BWG') {
            response = yield ContractService.saveBWGContract(payload);
        } else if (schema === 'N_INDEP_MOBILE') {
            response = yield ContractService.saveMobileContract(payload);
        }

        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_NUMBER, val: response?.data?.contractNumber ? response?.data?.contractNumber : response?.data?.contract?.contractNumber });
            if (schema === 'N_INDEP_MOBILE') {
                yield put({ type: SEND_SMS });
            }
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
